@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 320px;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #111;
  overflow: hidden;
}

/*
bg height: 1121px
bg width: 2000px
*/

.pat {
  position: absolute;
  top: -1121px;
  left: -2000px;
  height: calc(100% + 2 * 1121px);
  width: calc(100% + 2 * 2000px);
  background-image: url("/src/assets/images/bg-abstractgold.jpg");
  background-size: 2000px 1121px;
  animation: scroll 20s linear infinite;
  z-index: -1;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-2000px, -1121px, 0);
  }
}

@keyframes jumpy {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
